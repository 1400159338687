// src/components/section_types/VariableOptions.jsx
import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Checkbox, Radio } from 'react-bootstrap';

import { ValueType } from '@core/models/Variable';

import Fire from '@root/Fire';

@autoBindMethods
export default class VariableOptions extends Component {
  static propTypes = {
    variable: PropTypes.object.isRequired,
    section: PropTypes.object.isRequired,
  };

  static defaultProps = {
    variable: {
      valueType: '',
      options: [],
      value: '',
    },
    toggleListOption: () => {},
  };

  toggleListOption(variable, optionKey) {
    if (variable.valueType === ValueType.LIST) {
      console.log('Inside toggleListOption function type LIST');
      variable.value = optionKey;
    } else if (variable.valueType === ValueType.MULTI_SELECT) {
      console.log('Inside toggleListOption function type MULTI_SELECT');
      let currentValues = variable.value ? variable.value.split('\n') : [];
      if (currentValues.includes(optionKey)) {
        currentValues = currentValues.filter((value) => value !== optionKey);
      } else {
        currentValues.push(optionKey);
      }
      variable.value = currentValues.join('\n');
    }
    this.saveVariable(variable);
  }

  async saveVariable(variable) {
    const { section } = this.props;

    try {
      await Fire.saveVariable(section.deal, variable, variable.value);
      console.log(`Variable ${variable.name} saved successfully.`);
    } catch (error) {
      console.error(`Failed to save variable ${variable.name}:`, error);
    }
  }

  renderOptions() {
    const { variable } = this.props;
    const ComponentType = variable.valueType === ValueType.LIST ? Radio : Checkbox;
    const selectedValues = variable.value ? variable.value.split('\n') : [];

    return variable.options.map((option, idx) => (
      <div>
        <ComponentType
          key={`${option.key || option.value}-${idx}`}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onChange={() => {
            this.toggleListOption(variable, option.key || option.value);
          }}
          checked={
            variable.valueType === ValueType.LIST
              ? variable.value === option.key
              : selectedValues.includes(option.value)
          }
        >
          {option.title || option.label}
        </ComponentType>
      </div>
    ));
  }

  render() {
    const { variable } = this.props;

    if (!variable) return null;

    return (
      <div
        className={cx('orientation', {
          horizontal: variable.orientation === 'Horizontal',
          vertical: variable.orientation === 'Vertical',
        })}
      >
        {this.renderOptions()}
      </div>
    );
  }
}
